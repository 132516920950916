.title {
    text-align: center;
    font-size: 40px;
    color: #00cde1 !important;
    font-weight: 400;
    margin: 3rem 0;
  }
  .bgMobile {
    display: none;
    @media (max-width: 992px) {
      display: block;
      background-image: url("../../../public/assets/images/bg-clubBar-m.png");
      background-repeat: no-repeat;
      width: calc(100vw - 47px);
      height: calc(100vh - 1rem);
      background-size: 100% 100%;
      position: absolute;
      z-index: -1;
    }
  }
  .mainBox {
    gap: 24px;
    display: flex;
    @media (max-width: 992px) {
      flex-direction: column;
      background-color: transparent !important;
      border: 0px !important;
      box-shadow: unset !important;
    }
    .leftBox {
      // height: 636px;
      // padding: 24px;
      border: 1px solid #d6d6d9;
      border-radius: 18px;
      background: #fff;
      // flex-grow: 1;
      flex-basis: calc(50% - 12px);
      max-width: calc(50% - 12px);
      @media (max-width: 992px) {
        flex-basis: 100%;
        max-width: 100%;
        border: 0px solid #d6d6d9;
        border-radius: 0px;
        background: transparent;
      }
      .inner {
        padding-top: 6rem;
      }
      .bgImage {
        max-width: 100%;
        margin-top: -1.5rem;
        @media (max-width: 992px) {
          display: none;
        }
      }
    }
    .rightBox {
      padding: 24px;
      padding-right: 0;
      border: 1px solid #d6d6d9;
      border-radius: 18px;
      padding: 24px;
      background: #fff;
      flex-basis: calc(50% - 12px);
      max-width: calc(50% - 12px);
      @media (max-width: 992px) {
        flex-basis: 100%;
        max-width: 100%;
      }
      .inner {
        height: 588px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 24px;
      }
      // flex-grow: 1;
    }
  }
  .clubbar {
    width: 138px;
    @media (max-width: 600px) {
      width: 90px;
    }
    .h6 {
      font-size: 36px;
      @media (max-width: 992px) {
        font-size: 30px;
      }
      @media (max-width: 600px) {
        font-size: 24px;
      }
    }
  }
  