@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}

*::-webkit-scrollbar {
    display: none;
}

html{
    scroll-behavior: smooth;
    
}

body{
    font-family: 'Poppins', sans-serif;
}



  

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
}
