.splashMain {
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(
      180deg,
      rgba(0, 205, 225, 0) 22.4%,
      rgba(0, 205, 225, 0.52) 100%
    ),
    url("../../assets/images/splash-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  .containerBox {
    display: flex;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    gap: 4rem;
    // align-items: center;
    justify-content: space-between;
    .toprow {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      padding: 30px 0;
      align-items: center;
      img {
        height: auto;
        object-fit: contain;
        &:first-child {
          max-width: 180px;
          @media (max-width: 576px) {
            max-width: 120px;
          }
        }
        &:last-child {
          max-width: 80px;
          @media (max-width: 576px) {
            max-width: 60px;
          }
        }
      }
    }
    .contentText {
      h5.coppyRight {
        font-size: 32px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        margin: 0;
        @media (max-width: 992px) {
          font-size: 28px;
        }
        @media (max-width: 768px) {
          font-size: 24px;
        }
        @media (max-width: 576px) {
          font-size: 20px;
          font-weight: 500;
        }
      }
      .btnBox {
        display: flex;
        box-shadow: 9px 9px 11.5px 0px #00000021;
        border-radius: 50px;
        background-color: #fff;
        width: fit-content;
        margin: 3rem auto 0;
        .btn {
          color: #00cde1;
          background-color: #fff;
          border-radius: 50px;
          font-size: 36px;
          font-weight: 700;
          padding: 20px 70px;
          border: 0px;
          text-transform: capitalize;
          &.active {
            background: #00cde1;
            color: #fff;
          }
          &:hover {
            cursor: pointer;
          }
          @media (max-width: 992px) {
            font-size: 30px;
            padding: 15px 60px;
          }
          @media (max-width: 768px) {
            font-size: 24px;
            font-weight: 600;
            padding: 15px 50px;
          }
          @media (max-width: 576px) {
            font-size: 18px;
            font-weight: 500;
            padding: 15px 30px;
          }
        }
      }
    }
    h5.coppyRight {
      font-size: 20px;
      font-weight: 700;
      color: #fff;
      text-align: center;
      margin: 0;
      padding-bottom: 40px;
      @media (max-width: 768px) {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}