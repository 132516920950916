@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Black.eot');
    src: url('Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Black.woff2') format('woff2'),
        url('Roboto-Black.woff') format('woff'),
        url('Roboto-Black.ttf') format('truetype'),
        url('Roboto-Black.svg#Roboto-Black') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Bold.eot');
    src: url('Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Bold.woff2') format('woff2'),
        url('Roboto-Bold.woff') format('woff'),
        url('Roboto-Bold.ttf') format('truetype'),
        url('Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Light.eot');
    src: url('Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Light.woff2') format('woff2'),
        url('Roboto-Light.woff') format('woff'),
        url('Roboto-Light.ttf') format('truetype'),
        url('Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Medium.eot');
    src: url('Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Medium.woff2') format('woff2'),
        url('Roboto-Medium.woff') format('woff'),
        url('Roboto-Medium.ttf') format('truetype'),
        url('Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Regular.eot');
    src: url('Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Regular.woff2') format('woff2'),
        url('Roboto-Regular.woff') format('woff'),
        url('Roboto-Regular.ttf') format('truetype'),
        url('Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Thin.eot');
    src: url('Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Thin.woff2') format('woff2'),
        url('Roboto-Thin.woff') format('woff'),
        url('Roboto-Thin.ttf') format('truetype'),
        url('Roboto-Thin.svg#Roboto-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

body, h1, h2, h3, h4, h5, h6, p, div, span {
    font-family: 'Roboto';
    font-weight: 400;
}