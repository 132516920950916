.title {
  text-align: center;
  font-size: 40px;
  color: #00cde1;
  font-weight: 400;
  margin: 2rem 0;
}

.mainBox {
  // gap: 40px;
  // display: flex;
  // background: #fff;
  // border-radius: 0 24px 24px 0;
  // width: 100%;
  // margin: 0 auto;
  margin-top: -3rem;
  padding-bottom: 3rem;
  width: 100% !important;
  margin-left: 0 !important;
  @media (max-width: 1024px) {
    flex-direction: column !important;
  }
  // @media (max-width: 767px) {
  //   max-width: calc(100vw - 50px);
  // }
  // @media (max-width: 640px) {
  //   max-width: calc(100vw - 16px);
  // }
  .leftBox {
    margin-top: -10rem;
    padding: 30px 40px;
    background: #fff;
    // flex-basis: 400px;
    box-shadow: 0 10px 20px 0 #00000033;
    border-radius: 24px;
    max-width: 445px;
    height: max-content;
    @media (max-width: 1024px) {
      flex-basis: 100% !important;
      max-width: 100% !important;
      margin-top: -8rem;
      :global(.MuiRating-root) {
        margin-bottom: 16px !important;
      }
    }
    @media (max-width: 767px) {
      margin-top: -10rem;
    }
    @media (max-width: 640px) {
      padding: 20px !important;
    }
    .topLogoBox {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .logoImage {
        width: 164px;
        margin-bottom: 16px;
      }
      @media (max-width: 640px) {
        flex-direction: row;
        gap: 15px;
        .logoImage {
          width: 134px;
        }
      }
    }
    .badges {
      padding: 16px 30px;
      border-radius: 12px;
      background-color: rgba(217, 217, 217, 0.28);
      margin-top: 20px;
      display: flex;
      width: calc(100% - 60px);
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      @media (max-width: 640px) {
        padding: 14px 20px;
        width: calc(100% - 40px);
      }
      @media (max-width: 400px) {
        padding: 13px 15px;
        width: calc(100% - 30px);
      }
      .item {
        border-radius: 50%;
        // flex-grow: 1;
        height: 62px;
        width: 62px;
        background-color: #cccaca;
        @media (max-width: 640px) {
          height: 50px;
          width: 50px;
          gap: 16px;
        }
        @media (max-width: 400px) {
          height: 40px;
          width: 40px;
          gap: 10px;
        }
      }
    }
    .btnBox {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: space-between;
      .enroll {
        padding: 13px;
        font-size: 20px;
        color: #ffff;
        background-color: #00cde1;
        border-radius: 15px;
        width: 100%;
        box-shadow: unset;
      }
      .generate {
        padding: 13px;
        font-size: 20px;
        color: #00cde1;
        border: 1px solid #00cde1;
        position: relative;
        border-radius: 15px;
        width: 100%;
        background: transparent;
        box-shadow: unset;
        img {
          position: absolute;
          right: 30px;
        }
      }
      .collaborate {
        padding: 13px;
        font-size: 20px;
        color: #ffff;
        background-color: #14b3c1;
        border-radius: 15px;
        width: 100%;
        box-shadow: unset;
      }
    }
  }
  .rightBox {
    padding-top: 0 !important;
    flex-basis: calc(100% - 448px);
    max-width: calc(100% - 448px);
    // flex-grow: 1;
    @media (max-width: 1024px) {
      flex-basis: 100% !important;
      max-width: 100% !important;
      padding-left: 0 !important;
      padding-top: 48px !important;
    }
    .boxstyle {
      padding: 16px;
      background: #fff;
      box-shadow: 0 10px 20px 0 #00000033;
      border-radius: 24px;
      // width: calc(100% - 32px);
    }
    .profileIcon {
      width: 48px;
      height: 48px;
      max-width: 48px;
      max-height: 48px;
      border-radius: 50%;
      background-color: #d1d5da;
      border: 1px solid #00cde1;
      overflow: hidden;
      object-fit: contain;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        margin-top: 8px;
      }
    }
    .aboutContent {
      padding: 70px;
      // width: calc(100% - 140px);
      @media (max-width: 1400px) {
        padding: 60px 50px;
      }
      @media (max-width: 1200px) {
        padding: 50px;
      }

      @media (max-width: 1024px) {
        padding: 45px;
      }
      @media (max-width: 640px) {
        padding: 30px;
      }
      @media (max-width: 400px) {
        padding: 20px 15px;
      }
      .adminbox {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: flex-end;
        @media (max-width: 1200px) {
          gap: 15px;
        }

        @media (max-width: 1024px) {
          gap: 10px;
        }
        .boxItem {
          flex-basis: 30%;
          max-width: 30%;
          // min-width: 255px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          @media (max-width: 1400px) {
            flex-basis: 28%;
            max-width: 28%;
          }
          @media (max-width: 1200px) {
            flex-basis: 48%;
            max-width: 48%;
          }

          @media (max-width: 1024px) {
            flex-basis: 30%;
            max-width: 30%;
          }
          @media (max-width: 800px) {
            flex-basis: 47%;
            max-width: 47%;
          }
          @media (max-width: 640px) {
            //   flex-basis: 50%;
            //   max-width: 50%;
            //   min-width: unset;
            align-items: flex-start;
          }
          .image {
            border-radius: 50%;
            // flex-grow: 1;
            height: 100px;
            width: 100px;
            background-color: #cccaca;
            @media (max-width: 1400px) {
              height: 90px;
              width: 90px;
            }
            @media (max-width: 1200px) {
              height: 85px;
              width: 85px;
            }

            @media (max-width: 1024px) {
              height: 75px;
              width: 75px;
            }
            @media (max-width: 640px) {
              height: 50px;
              width: 50px;
            }
          }
          .conetnttext {
            flex-grow: 1;
          }
        }
      }
    }
    .commentForm {
      margin: 25px 0;
      display: flex;
      align-items: center;
      :global(.MuiInputBase-root) {
        flex-grow: 1;
        margin: 0 -20px 0 -20px;
        input {
          font-size: 16px;
          color: black;
          background-color: #d1d5da;
          min-height: 40px;
          padding: 5px 25px;
          ::placeholder {
            color: #2e2e2e;
          }
        }
      }
      .postBtn {
        padding: 11px 30px;
        font-size: 16px;
        color: #ffff;
        background-color: #00cde1;
        border-radius: 50px;
        box-shadow: unset;
        min-height: 40px;
      }
    }
    .profileDetail {
      margin-bottom: 20px;
      .titleHeader {
        display: flex;
        align-items: center;
        gap: 5px;
        .moreBtn {
          padding: 10px 30px;
          background-color: #00cde1;
          border-radius: 50px;
          box-shadow: unset;
          min-height: 40px;
        }
      }
    }
    .featureBox {
      width: 100%;
      max-height: 450px;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
      overflow: hidden;
    }
    .btnsBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;
      padding: 10px 0;
      @media (max-width: 540px) {
        gap: 10px;
      }
      .fill {
        padding: 10px 30px;
        background-color: #00cde1;
        border-radius: 50px;
        box-shadow: unset;
        min-height: 40px;
        @media (max-width: 540px) {
          padding: 10px 15px;
        }
      }
      .noFill {
        padding: 10px 30px;
        background-color: transparent;
        border-radius: 50px;
        box-shadow: unset;
        min-height: 40px;
        font-size: 12px;
        font-weight: 500;
        border: 1px solid #00cde1;
        color: #00cde1;
        @media (max-width: 540px) {
          padding: 10px 15px;
        }
      }
    }
  }
}
