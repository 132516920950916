.LoginMain {
  width: 100%;
  height: 100vh;
  .containerBox {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 992px) {
      flex-direction: column;
      position: relative;
    }
    h5.coppyRight {
      font-size: 20px;
      font-weight: 700;
      color: #fff;
      text-align: center;
      margin: 0;
      padding-bottom: 40px;
      @media (max-width: 768px) {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 20px;
      }
    }
    .left {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      background-image: linear-gradient(
          180deg,
          rgba(0, 205, 225, 0) 22.4%,
          rgba(0, 205, 225, 0.52) 100%
        ),
        url("../../assets/images/splash-bg.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      @media (max-width: 992px) {
        justify-content: space-between;
        padding: 0 20px;
      }
      .toprow {
        @media (min-width: 992.1px) {
          display: none;
        }
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding: 20px 0;
        width: 100%;
        align-items: center;
        img {
          height: auto;
          object-fit: contain;
          &:first-child {
            max-width: 180px;
            @media (max-width: 576px) {
              max-width: 120px;
            }
          }
          &:last-child {
            max-width: 80px;
            @media (max-width: 576px) {
              max-width: 60px;
            }
          }
        }
      }
      .mainLogo {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 992px) {
          display: none;
        }
        max-width: 350px;
        @media (min-width: 1400px) {
          max-width: 400px;
        }
        @media (max-width: 1200px) {
          max-width: 280px;
        }
        @media (max-width: 992px) {
          max-width: 230px;
        }
      }
    }

    .right {
      background-color: #fff;
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 992px) {
        width: 100%;
        width: calc(100% - 40px);
        position: absolute;
        height: calc(100vh - 200px);
        top: 7rem;
        border-radius: 45px;
        overflow: auto;
        box-shadow: 9px 9px 35px 0px #00000017;
        left: 20px;
        right: 20px;
      }
      .form {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 3rem 4rem 0 4rem;
        @media (max-width: 1200px) {
          padding: 2rem 3rem 0 3rem;
        }
        @media (max-width: 992px) {
          padding: 20px 40px 0 40px;
        }
        @media (max-width: 768px) {
          padding: 20px 40px 0 40px !important;
        }
        @media (max-width: 480px) {
          padding: 20px 40px 0 40px !important;
        }
      }
      h1.title {
        font-size: 40px;
        font-weight: 700;
        color: #000;
        margin: 0;
        padding-bottom: 70px;
        @media (max-width: 992px) {
          font-size: 32px;
          font-weight: 700;
          padding-bottom: 50px;
        }
        @media (max-width: 768px) {
          font-size: 24px;
          font-weight: 700;
          padding-bottom: 30px;
        }
      }
      h5.label {
        font-size: 24px;
        font-weight: 700;
        color: #0000008f;
        margin: 1rem 0;
        padding-left: 10px;
        @media (max-width: 992px) {
          font-size: 22px;
          font-weight: 600;
        }
        @media (max-width: 768px) {
          font-size: 20px;
          font-weight: 500;
        }
        @media (max-width: 480px) {
          font-size: 16px;
          font-weight: 400;
        }
      }
      input,
      :global(.MuiSelect-select),
      :global(.MuiInputBase-multiline) {
        padding: 15px 20px;
        border: 1px solid #d9d9d987;
        background: #d9d9d987;
        font-size: 18px;
        font-weight: 400;
        color: #00272b;
        border-radius: 15px;
        &:hover,
        &:focus {
          border: 1px solid #414141;
          background: #fff;
        }
        @media (max-width: 992px) {
          font-size: 32px;
          padding: 13px 18px;
        }
        @media (max-width: 768px) {
          font-size: 16px;
          font-weight: 400;
          padding: 12px 15px;
        }
        @media (max-width: 480px) {
          font-size: 14px;
          font-weight: 400;
          padding: 8px 10px;
        }
      }
      fieldset {
        border-color: transparent;
        legend {
          span {
            background: #fff;
          }
        }
      }
      :global(.MuiCheckbox-root) {
        color: #000000c7;
        &:global(.Mui-checked) {
          color: #fff;
          svg {
            fill: #000;
          }
        }
      }
      a.link {
        font-size: 20px;
        font-weight: 700;
        color: #14b3c1;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
        @media (max-width: 992px) {
          font-size: 18px;
          font-weight: 600;
        }
        @media (max-width: 768px) {
          font-size: 16px;
          font-weight: 500;
        }
        @media (max-width: 480px) {
          font-size: 16px;
          font-weight: 400;
        }
      }
      h3.textCaption {
        font-size: 20px;
        font-weight: 700;
        color: #000000c7;
        text-align: center;
        margin: 2.5rem 0 12px 0;
        @media (max-width: 992px) {
          font-size: 18px;
          font-weight: 600;
        }
        @media (max-width: 768px) {
          font-size: 16px;
          font-weight: 500;
        }
        @media (max-width: 480px) {
          font-size: 16px;
          font-weight: 400;
        }
      }
      h4.textCaption {
        font-size: 18px;
        font-weight: 700;
        color: #0000009c;

        padding: 0 4rem 3rem 4rem;
        @media (max-width: 1200px) {
          padding: 0rem 3rem 2rem 3rem;
        }
        @media (max-width: 992px) {
          font-size: 16px;
          font-weight: 600;
          padding: 0px 55px 50px 55px;
        }
        @media (max-width: 768px) {
          font-size: 14px;
          font-weight: 500;
          padding: 0px 55px 50px 55px;
        }
        @media (max-width: 480px) {
          font-size: 16px;
          font-weight: 400;
          padding: 0px 55px 50px 55px;
        }
        // text-align: center;
        // margin: 2.5rem 0;
      }
    }
  }
}