.title {
  text-align: center;
  font-size: 40px;
  color: #00cde1;
  font-weight: 400;
  margin: 2rem 0;
}

.mainBox {
  //   gap: 24px;
  display: flex;
  background: #fff;
  border-radius: 0 24px 24px 0;
  @media (max-width: 992px) {
    border-radius: 24px;
    padding: 16px;
    flex-direction: column;
    border: 0px !important;
    box-shadow: unset !important;
  }
  .leftBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
    background: #fff;
    // flex-grow: 1;
    flex-basis: 30%;
    max-width: 30%;
    @media (max-width: 992px) {
      flex-basis: 100%;
      max-width: 100%;
      border: 0px solid #d6d6d9;
      border-radius: 0px;
      background: transparent;
      :global(.MuiRating-root) {
        margin-bottom: 16px !important;
      }
    }
    .inner {
      padding-top: 6rem;
    }
    .bgImage {
      max-width: 100%;
      margin-top: -1.5rem;
      @media (max-width: 992px) {
        display: none;
      }
    }
  }
  .rightBox {
    border-radius: 8px;
    box-shadow: 0px 4px 10px #0000001f;
    padding: 60px 40px;
    border-radius: 24px;
    background: #fff;
    flex-basis: 70%;
    max-width: 70%;
    .customtextarea {
      border: 0px solid #00272b40;
      :global(.MuiInputBase-root) {
        background: #f5f5f5;
        border: 0px solid #00272b40;
        border-radius: 6px;
        box-shadow: unset;
        &:hover,
        &:focus {
          background: #f5f5f5;
          border: 1px solid #00272b40;
          border-radius: 6px;
        }
      }
    }
    @media (max-width: 992px) {
      flex-basis: 100%;
      max-width: 100%;
      padding: 40px 20px;
    }
  }
}

.feedbackOverview {
  .topHeader {
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 4rem;
    margin-bottom: 3rem;
    @media (max-width: 992px) {
      flex-direction: column;
      gap: 16px;
    }
    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .right {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .inner {
        display: flex;
        align-items: center;
        gap: 12px;
        @media (max-width: 500px) {
          flex-direction: column;
          gap: 16px;
        }
        p {
          font-size: 40px;
          color: #000;
          margin: 0;
          &.titletext {
            margin-right: 20px;
          }
          @media (max-width: 992px) {
            font-size: 34px;
          }
          @media (max-width: 767px) {
            font-size: 28px;
          }
        }
      }
    }
  }
  .masonaryBox {
    .masonaryItem {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #fff;
      padding: 40px;
      border-radius: 36px;
      gap: 16px;
      .img {
        width: 78px;
        height: 78px;
        border-radius: 50%;
        object-fit: cover;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      p {
        font-size: 20px;
        margin: 0;
        text-align: center;
        color: #000;
        &.desc {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.nodesc {
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
