.customModal {
    max-width: calc(800px - 20px);
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 24px;
    padding: 10px;
    overflow: hidden;
    background-color: #fff;
    @media (max-width: 780px) {
      padding: 0px;
    }
    .inner {
      padding: 40px;
      height: calc(100vh - 8rem);
      overflow: auto;
      border-radius: 24px;
      @media (max-width: 1024px) {
        padding: 32px;
      }
      @media (max-width: 768px) {
        padding: 26px;
      }
      @media (max-width: 576px) {
        padding: 16px;
      }
    }
    .topHeader {
      display: flex;
      flex-direction: column;
      gap: 60px;
      align-items: center;
      justify-content: center;
      text-align: center;
      @media (max-width: 1024px) {
        gap: 45px;
      }
      @media (max-width: 768px) {
        gap: 30px;
      }
      @media (max-width: 576px) {
        gap: 20px;
      }
    }
    h1.title {
      font-size: 32px;
      font-weight: 700;
      color: #000;
      line-height: 34px;
      @media (max-width: 1024px) {
        font-size: 28px;
      }
      @media (max-width: 768px) {
        font-size: 24px;
      }
      @media (max-width: 576px) {
        font-size: 24px;
      }
    }
    h2.title {
      font-size: 32px;
      font-weight: 700;
      color: #14b3c1;
      line-height: 34px;
      margin-bottom: 60px;
      @media (max-width: 1024px) {
        font-size: 28px;
      }
      @media (max-width: 768px) {
        font-size: 24px;
      }
      @media (max-width: 576px) {
        font-size: 24px;
      }
      span {
        font-weight: 400;
      }
    }
    h4.title {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      line-height: 25.2px;
      @media (max-width: 576px) {
        font-size: 16px;
      }
    }
    p.detail {
      font-size: 18px;
      font-weight: 400;
      color: #000;
      line-height: 25.2px;
      @media (max-width: 576px) {
        font-size: 16px;
      }
    }
    .eventDetails {
      display: flex;
      flex-direction: column;
      gap: 30px;
      @media (max-width: 576px) {
        gap: 20px;
      }
      .profileBox {
        display: flex;
        gap: 100px;
        align-items: center;
        @media (max-width: 1024px) {
          gap: 60px;
        }
        @media (max-width: 768px) {
          gap: 40px;
          flex-wrap: wrap;
        }
        @media (max-width: 576px) {
          gap: 20px;
        }
        .image {
          width: 92px;
          height: 92px;
          border-radius: 50%;
          overflow: hidden;
          object-fit: cover;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .datebox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        h4.title {
          font-size: 20px;
          font-weight: 400;
          color: #000;
          line-height: 25.2px;
          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
      }
    }
  }